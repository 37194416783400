/* Global Imports
----------------------------------------------------------------------------------------------------*/
@import 'partials/variables';
@import 'partials/normalize';
@import 'partials/fonts';
@import 'partials/skeleton';
@import 'partials/mixins';

/* Global HTML
----------------------------------------------------------------------------------------------------*/

* {
  box-sizing: border-box;
}

@import 'partials/grid';

.ui-loader {
  display: none;
}

.section-fill {
  height: 100vh;
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.section-container {
  @include max_width(1134px);
  color: #737372;
  margin: 8em auto;

  @include large-mobile {
    margin: 3em auto;
  }
}

.purple {
  color: $sitepurple;
}

.marker {
  background-color: $sitegold;
  border-radius: 2px;
  padding: 2px 4px;
}

nav {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

@import 'partials/header';
@import 'partials/footer';
@import 'partials/social';
@import 'partials/tabs';
@import 'partials/featured-work';
@import 'partials/richtext';

/* Component Imports
----------------------------------------------------------------------------------------------------*/
@import 'partials/mastheads';
@import 'partials/navigation';
@import 'partials/intros';
@import 'partials/leaders';
@import 'partials/sliders';
@import 'partials/benefits';
@import 'partials/directory';
@import 'partials/forms';
@import 'partials/search';
@import 'partials/contacts';
@import 'partials/category-nav';

/* Page Imports
----------------------------------------------------------------------------------------------------*/
@import 'pages/home';
@import 'pages/agencies';
@import 'pages/brands';
@import 'pages/about';
@import 'pages/news';
@import 'pages/media';
@import 'pages/life';
@import 'pages/terms';
