.why {
  background-image: url('../images/home/why-bg.png');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  background-color: #f3bc41;
  margin-bottom: 0;
  height: inherit;
  min-height: 750px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @include desktop-large {
    min-height: 900px;
  }
  @include tablet {
    min-height: 800px;
  }
  @include large-mobile-landscape {
    min-height: 600px;
  }
  @include large-mobile {
    min-height: 690px;
  }
  @include small-mobile {
    min-height: 550px;
  }
  h1 {
    color: #ffffff;
    font-size: 15rem;
    text-align: left;
    margin-bottom: 0;
    position: absolute;
    top: 100px;
    left: 100px;
    @include desktop-large {
      left: 50px;
      top: 50px;
    }
    @include desktop-small {
      font-size: 10rem;
    }
    @include tablet {
      font-size: 8rem;
      top: 30px;
      left: 50px;
    }
    @include large-mobile {
      font-size: 3.5rem;
      left: 5%;
    }
    @include large-mobile-landscape {
      font-size: 6.5rem;
    }
  }
  .circular-slider {
    width: 620px;
    height: 620px;
    margin: 0 auto;
    position: relative;
    @include large-desktop {
      height: 550px;
      width: 550px;
    }
    @include large-mobile {
      width: 130vw;
      height: 130vw;
      min-height: 435px;
      margin-left: -15vw;
    }
    @include large-mobile-landscape {
      width: 450px;
      height: 450px;
    }
    @include small-mobile-landscape {
      width: 480px;
      height: 480px;
      margin-left: auto;
    }
    .slides-wrapper {
      background-color: rgba(#fff, 0.9);
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
    .slides-header {
      margin: 0 auto;
      padding-top: 150px;
      width: 400px;
      @include large-desktop {
        padding-top: 120px;
        width: 350px;
      }
      @include large-mobile-landscape {
        padding-top: 125px;
        width: 70%;
      }
      @include large-mobile {
        width: 65%;
      }
      h2,
      li,
      nav,
      ul {
        display: inline-block;
      }
      h2 {
        color: $sitepurple;
        font-family: $body_font;
        font-size: 2rem;
        letter-spacing: 0.2em;
        margin-right: 10px;
        text-transform: uppercase;
        @include large-desktop {
          font-size: 1.75rem;
        }
      }
      ul {
        position: relative;
        top: -0.4em;
      }
      a {
        background-color: $sitepurple;
        display: block;
        height: 3px;
        margin-right: 2px;
        opacity: 0.2;
        overflow: hidden;
        text-indent: 100%;
        transition: 0.25s opacity ease;
        width: 30px;
      }
      a:hover,
      li.active a {
        opacity: 1;
      }
    }
    .slide-pager {
      a {
        @include image_replace( 'sliders/circle-nav.png', 100px, 100px);
        margin-top: -50px;
        position: absolute;
        right: 115%;
        top: 50%;
        @include large-desktop {
          height: 50px;
          width: 50px;
          background-size: 100px 50px;
          margin-top: -25px;
        }
        @include desktop-only {
          right: 110%;
        }
        @include tablet {
          transform: scale(0.5);
          right: 98%;
        }
        @include large-mobile {
          background-image: url("../images/sliders/left_mobile_nav.png");
          height: 30px;
          width: 17px;
          background-size: contain;
          right: 75%;
          transform: none;
          margin-top: -15%;
        }
        @include small-mobile {
          right: 80%;
          margin-top: -10%;
        }
        @include small-mobile-landscape {
          margin-top: -10%;
        }
        
        &.next {
          background-position: right 0;
          left: 115%;
          right: auto;
          @include desktop-only {
            left: 110%;
          }
          @include tablet {
            left: 98%;
          }
          @include large-mobile {
            left: 75%;
            transform: scaleX(-1);
            filter: FlipH;
          }
          @include small-mobile {
            left: 80%;
          }
        }
      }
    }
    .slides {
      margin: 0 auto;
      width: 400px;
      @include large-desktop {
        width: 350px;
      }
      @include large-mobile-landscape {
        width: 70%;
      }
      @include large-mobile {
        width: 65%;
      }
    }
    .slide {
      height: 0;
      opacity: 0;
      pointer-events: none;
      //transition-delay: 0.75s;
      transition: 0.75s opacity ease;
      h3 {
        color: $sitepurple;
        font-size: 6rem;
        letter-spacing: 0.02em;
        text-transform: lowercase;
        position: relative;
        @include large-desktop {
          font-size: 6rem;
        }
        @include large-mobile {
          font-size: 9vw;
        }
        @include large-mobile-landscape {
          font-size: 4rem;
        }
      }
      p {
        font-size: 2rem;
        letter-spacing: 0.03em;
        opacity: 0.8;
        @include large-desktop {
          font-size: 1.8rem;
        }
        @include large-mobile {
          font-size: 4vw;
        }
        @include large-mobile-landscape {
          font-size: 1.4rem;
        }
      }
      &.active {
        height: auto;
        opacity: 1;
        pointer-events: auto;
        //transition-delay: 0s;
      }
    }
  }
}
.benefits,
.statistics {
  .slide-nav {
    margin: 0 auto;
    max-width: 1000px;
    ul {
      align-content: center;
      border-bottom: 1px solid #818181;
      display: flex;
      justify-content: space-between;
      margin-bottom: 100px;
      text-transform: uppercase;
    }
    li {
      margin-bottom: 0;
      position: relative;
    }
    a {
      color: #c0c0c0;
      display: block;
      padding-bottom: 15px;
      position: relative;
      &:after {
        background-color: #818181;
        border-radius: 50%;
        bottom: -7px;
        content: '';
        display: block;
        height: 14px;
        left: 50%;
        margin-left: -7px;
        position: absolute;
        width: 14px;
        z-index: 2;
      }
    }
    li:first-child,
    li:last-child {
      &:before {
        background-color: #fff;
        bottom: -2px;
        content: '';
        display: block;
        height: 3px;
        position: absolute;
        width: 50%;
        z-index: 1;
      }
    }
    li:first-child {
      &:before {
        left: 0;
      }
    }
    li:last-child {
      &:before {
        right: 0;
      }
    }
    a:hover,
    li.active a {
      color: #818181;
      font-weight: bold;
      &:after {
        background-color: $siteltgreen;
      }
    }
  }
}
.benefits {
  .slide-nav {
    ul {
      align-items: flex-end;
      @include large-mobile {
        margin-bottom: 50px;
      }
    }
    li {
      position: relative;
      padding-top: 20px;
      &.active figure,
      &:hover figure {
        background-position: left bottom;
      }
      &.active span {
        display: block;
      }

    }
    a {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;
      span {
        @include large-mobile {
          display: none;
        }
      }
      figure {
        background-position: left top;
        background-repeat: no-repeat;
        height: 45px;
        overflow: hidden;
        width: 50px;
        white-space: nowrap;
        background-size: 50px auto;
        margin: 0 0 10px;
        @include large-mobile {
          width: 25px;
          height: 23px;
          background-size: 25px auto;
        }
      }
    }
  }
}
.statistics {
  .slide-nav {
    font-family: $header_font_bold;
    font-size: 6rem;
    width: 1000px;
    @include large-mobile {
      font-size: 5vw;
      width: 100%;
    }
    a {
      color: #7a7a7a;
      &:after {
        background-color: #fff;
      }
    }
    li:first-child a,
    li:last-child a {
      &:before {
        background-color: #4d4d4d;
      }
    }
    a:hover,
    li.active a {
      color: #fff;
    }
  }
}
