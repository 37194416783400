.news-list {
  background-image: url('../images/about/dots.png');
  background-repeat: no-repeat;
  background-position: top left;
  padding: 12rem 0;
  height: inherit;

  @include large-mobile {
    padding: 6rem 0;
  }

  .flex-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    height: inherit;
    max-width: 1100px;
  }

  .news-results {
    width: 100%;
  }

  .news-content {
    width: 100%;

    .item {
      @include clearfix;
      color: $footergrey;
      margin-bottom: 4rem;
      width: 100%;

      @include large-mobile {
        margin-bottom: 2rem;
      }

      img {
        width: 100%;

        @include large-mobile {
          margin-bottom: 1em;
          width: 50%;
        }
      }

      p {
        font-family: $header_font_bold;

        @include large-mobile {
          font-size: 1.2rem;
          margin-bottom: 1em;
        }
      }

      span {
        text-transform: uppercase;
      }

      h4 {
        color: $footergrey;
        font-size: 3.6rem;
        font-weight: 700;
        line-height: 1.3;

        @include large-mobile {
          font-size: 2.2rem;
        }
      }
    }
  }
}

.news-inner {
  .masthead {
    hgroup.small {
      h1 {
        font-size: 4rem;

        @include large-mobile {
          font-size: 2.5rem;
          line-height: 1.5;
        }
      }
    }
  }

  .masthead.has-image {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;

    .single-wrapper {
      align-items: center;
      background-color: rgba(47, 128, 171, 0.75);
      display: flex;
      flex-direction: column;
      height: 50%;
      justify-content: center;
      width: 45%;
    }

    hgroup {
      width: 75%;

      h1 {
        font-size: 3em;
      }

      p {
        color: #ffffff;
        font-family: $body_font;
        font-size: 14px;
        letter-spacing: .2em;
        margin-bottom: 15px;
        text-align: left;
        text-transform: uppercase;

        strong {
          font-family: $sub_header_font_bold;
        }
      }
    }
  }

  .news-single {
    height: inherit;
    padding: 12rem 0;

    @include large-mobile {
      padding: 6rem 0;
    }

    .news-social {
      background-color: #c24f97;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      float: right;
      box-sizing: border-box;
      width: 50%;
      height: inherit;
      padding: 1em 0;

      @include tablet {
        flex-direction: row;
        float: none;
        justify-content: space-around;
        width: 75%;
        padding: .75em 0;
      }

      li {
        margin: 1em;

        @include large-mobile {
          margin: 0;
        }
      }

      .social-icon {
        opacity: 1;
      }
    }

    article {
      color: $footergrey;
      font-size: 2rem;
      line-height: 2;
      padding-right: 100px;

      @include tablet {
        padding-right: 0;
      }

      @include large-mobile {
        font-size: 1.5rem;
      }

      p {
        margin-bottom: 2em;
      }

      h1 {
        font-size: 4rem;
      }

      h2 {
        font-size: 3.6rem;
      }

      h3 {
        font-size: 3.2rem;
      }

      h4 {
        font-size: 2.6rem;
      }

      h5 {
        font-size: 2rem;
      }

      h6 {
        font-size: 1.8rem;
      }
    }
  }
}
