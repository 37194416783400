.section-benefits {
  background: #ffba27 url('../images/brands/map.png') no-repeat center center;
  padding: 10rem 0;

  @include large-mobile {
    padding: 4rem 0;
  }

  .wrapper {
    @include max_width(1400px);
  }

  h1 {
    color: #fff;
    font-size: 6em;
    margin-bottom: 1em;

    @include large-mobile {
      font-size: 3.2em;
    }
  }

  ul {
    align-content: center;
    border-bottom: 1px solid #4d4d4d;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 100px;
    text-align: center;
    text-transform: uppercase;
    width: 90%;

    @include large-mobile-landscape {
      margin-bottom: 40px;
      width: 100%;
    }

    @include large-mobile {
      margin-bottom: 40px;
      width: 100%;
    }
  }

  li {
    margin-bottom: 0;
    position: relative;

    &:first-child,
    &:last-child {
      &:before {
        background-color: #ffba27;
        bottom: -2px;
        content: '';
        display: block;
        height: 3px;
        position: absolute;
        width: 50%;
        z-index: 1;
      }
    }

    &:first-child {
      &:before {
        left: 0;
      }
    }

    &:last-child {
      &:before {
        right: 0;
      }
    }
  }

  a {
    color: #fff;
    display: block;
    filter: invert(72%);
    padding-bottom: 15px;
    position: relative;
    z-index: 2;

    &:after {
      background-color: #fff;
      border-radius: 50%;
      bottom: -7px;
      content: '';
      display: block;
      height: 14px;
      left: 50%;
      margin-left: -7px;
      position: absolute;
      width: 14px;
      z-index: 2;
    }

    figure {
      height: 55px;
      margin: 0;
    }

    span {
      display: block;
    }

    @include large-mobile-landscape {
      font-size: 1.4rem;
    }

    @include large-mobile {
      height: 55px;

      figure {
        height: 25px;
      }

      img {
        position: relative;
        top: 0;
        transform: scale(0.5);
        transition: 0.25s all ease;
      }

      span {
        display: none;
        position: relative;
        top: -10px;
      }
    }

    @include medium-mobile {
      font-size: 12px;

      figure {
        height: 20px;
      }
    }
  }

  li.active a, a:hover {
    filter: invert(0%);
  }

  li.active a {
    @include large-mobile {
      img {
        top: -40px;
        transform: scale(1);
      }

      span {
        display: block;
      }
    }
  }

  .slides {
    height: 150px;

    @include large-mobile-landscape {
      height: 250px;
    }

    @include large-mobile {
      height: 300px;
    }

    @include medium-mobile-landscape {
      height: 175px;
    }

    @include medium-mobile {
      height: 175px;

      .brands & {
        height: 100px;
      }
    }
  }

  .slide {
    @include max_width(760px);
    color: #575756;
    font-size: 2rem;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    text-align: center;
    transition-delay: 0.75s;
    transition: 0.75s all ease;

    &.active {
      max-height: 300px;
      opacity: 1;
      pointer-events: auto;
      transition-delay: 0;
    }

    @include large-mobile {
      font-size: 1.5rem;
    }

    @include medium-mobile {
      font-size: 1.3rem;
    }
  }
}