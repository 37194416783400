#home-masthead {
  background-color: #2e2e2e;
  height: 100vh;
  height: calc(100vh + 50px);
  margin-top: -120px;
  min-height: 0;
  overflow: visible;
  position: relative;
  z-index: 10;

  .sections {
    background-color: #333333;
    height: 100%;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    .section {
      background: #333333 url('../images/home/agencies.png') no-repeat left center;
      background-size: cover;
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      transform: translateY(-100%);
      transition: 0.35s all ease;
      top: 0;
      width: 66.25%;
      z-index: 1;

      clip-path: polygon(0 0, 100% 0, 55% 100%, 0% 100%);

      a {
        color: #fff;
        display: block;
        height: 100%;
        width: 100%;
      }

      p {
        font-family: $header_font_bold;
        font-size: 7.2rem;
        line-height: 1.25;
        margin: 0;
        opacity: 0;
        position: absolute;
        right: 28%;
        text-transform: lowercase;
        transform: translateX(-10%);
        transition: 0.35s all ease;
        top: 48%;
        @include large-mobile {
          font-size: 9vw;
          top: 50.5%;
        }
        @include large-mobile-landscape {
          font-size: 7vw;
        }
        @include small-mobile-landscape {
          line-height: 0.5;
        }
        small {
          display: block;
          font-family: $body_font;
          font-size: 1.6rem;
          letter-spacing: 4px;
          padding-left: 82px;
          position: relative;
          text-transform: uppercase;

          &:after {
            background-color: $siteltblue;
            content: '';
            display: block;
            height: 3px;
            left: 5px;
            margin-top: -4px;
            position: absolute;
            top: 50%;
            width: 50px;
          }

          @include large-mobile {
            display: none;
          }
        }
      }

      &:last-child {
        background-image: url('../images/home/brands.png');
        background-position: right center;
        left: auto;
        right: 0;
        transform: translateY(100%);
        clip-path: polygon(45% 0, 100% 0, 100% 100%, 0 100%);

        p {
          left: 18%;
          right: auto;
          top: 55.5%;
          transform: translateX(10%);

          @include small-mobile-landscape {
            left: 20%;
          }
        }
      }

      &:hover {
        background-color: #2f80ab;
        p {
          font-size: 10rem;
          right: 33%;
          @include large-mobile {
            font-size: 5rem;
          }
          @include large-mobile-landscape {
            font-size: 8rem;
          }
        }

        &:last-child {
          p {
            left: 23%;
            right: auto;
          }
        }
      }
    }
  }

  .headline-wrapper {
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: 0.5s all ease;
    transition-delay: 1s;
    width: 100%;
    z-index: 2;
  }

  .headline {
    background-color: #3079a0;
    border-radius: 50%;
    height: 90%;
    left: 50%;
    position: absolute;
    top: 5%;
    transform: translateX(-50%);

    img {
      display: block;
      height: 100%;
      width: auto;
    }

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 7.2rem;
      line-height: 1;
      margin: 0;
      padding: 0 12%;
      text-transform: lowercase;
      width: 100%;
      @include desktop-large-height {
        font-size: 6.2rem;
      }
      @include desktop-height {
        font-size: 5rem;
      }
      @include desktop-large {
        font-size: 6rem;
      }
      @include desktop-small {
        font-size: 5.4rem;
      }
      @include tablet {
        font-size: 5rem;
      }
      @include large-mobile-landscape {
        font-size: 3rem;
      }
      @include large-mobile {
        font-size: 3rem;
        padding: 0 15%;
      }
      @include medium-mobile-landscape {
        font-size: 2rem;
      }
      @include medium-mobile {
        font-size: 2.5rem;
        padding: 0 20%;
      }
      small {
        display: block;
        font-family: $body_font;
        font-size: 2rem;
        font-weight: normal;
        letter-spacing: 4px;
        margin-bottom: 2rem;
        padding-left: 66px;
        position: relative;
        text-transform: uppercase;

        &:after {
          background-color: $siteltblue;
          content: '';
          display: block;
          height: 3px;
          left: 5px;
          margin-top: -4px;
          position: absolute;
          top: 50%;
          width: 50px;
        }

        @include large-mobile {
          font-size: 1.8rem;
        }

        @include medium-mobile-landscape {
          font-size: 1.5rem;
          padding-left: 38px;

          &:after {
            width: 25px;
          }
        }

        @include medium-mobile {
          font-size: 1.3rem;
        }
      }
    }

    @include large-desktop {
      height: 80%;
      top: 15%;
    }

    @include tablet {
      height: 80%;
      top: 15%;
    }

    @include large-mobile-landscape {
      top: 12%;
    }

    @include large-mobile {
      height: 70%;
      top: 20%;
    }

    @include medium-mobile-landscape {
      top: 20%;
    }

    @include small-mobile-landscape {
      top: 25%;
    }
  }

  .scroll {
    bottom: -15%;
    opacity: 0;
    position: absolute;
    text-align: center;
    transition: 0.5s all ease;
    transition-delay: 1.25s;
    width: 100%;
    z-index: 3;

    p {
      color: #fff;
      font-size: 1rem;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    &:after {
      background-color: $siteltblue;
      content: '';
      display: block;
      height: 175px;
      left: 50%;
      margin-left: -2px;
      position: absolute;
      top: 40px;
      width: 3px;
      @include tablet {
        height: 100px;
      }
    }

    .no-js & {
      display: none;
    }

    @include medium-mobile-landscape {
      bottom: 8%;
    }
  }
}

#home-masthead.active {
  .sections {
    .section, .section:last-child {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .headline-wrapper {
    opacity: 1;
  }

  .scroll {
    bottom: 15%;
    opacity: 1;

    @include medium-mobile-landscape {
      bottom: 8%;
    }
  }
}

#home-masthead.open {
  .sections {
    pointer-events: auto;

    .section {
      p {
        opacity: 1;
        transform: translateX(0%);
      }
    }
  }

  .headline-wrapper {
    opacity: 0;
    pointer-events: none;
    transition-delay: 0s;
  }

  .scroll {
    bottom: 10%;
    transition-delay: 0s;
    @include large-mobile {
      bottom: 10%;
    }
  }
}

.no-cssclippathpolygon {
  #home-masthead {
    .sections {
      .section {
        width: 50%;
      }
    }
  }
}

.home {
  .intro {
    background: url('../images/home/map.png') no-repeat center center;
    background-size: cover;
    color: #696968;

    @include tablet {
      height: auto;

      .five.columns, .six.columns {
        width: 100%;
      }

      .offset-by-one {
        margin: 0;
      }
    }

    .container{
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include tablet {
        display: block;
        height: auto;
        padding: 14rem 0 12rem;
        width: 400px;
      }

      @include large-mobile {
        width: 350px;
      }

      @include small-mobile {
        width: 250px;
      }
    }

    h1 {
      color: #696968;
      font-size: 13rem;
      border-bottom: 8px $siteltgreen solid;
      text-align: center;
      @include desktop-small {
        font-size: 10rem;
      }
      @include tablet {
        font-size: 9rem;
      }
      @include large-mobile-landscape {
        border-width: 4px;
        font-size: 7.5rem;
      }
      @include large-mobile {
        border-width: 4px;
        font-size: 7rem;
        line-height: 1;
        margin-bottom: 0.25em;
        width: 200px;
      }
    }
    p {
      font-size: 2rem;
      line-height: 1.5;
      text-align: left;

      @include large-mobile-landscape {
        font-size: 1.7rem;
      }

      @include large-mobile {
        font-size: 1.5rem;
        margin-bottom: 0;
      }
    }
  }

  .featured-work {
    background-image: url('../images/home/featured-bg.jpg');
    background-position: top center;
    background-color: #202020;
    background-repeat: no-repeat;
    background-size: 100% auto;

    .section-title {
      h1 {
        @include desktop-small {
          float: none;
          text-align: center;
        }

        @include large-mobile {
          margin-bottom: 0.5em;
        }
      }
    }
  }
}