/* Variables
----------------------------------------------------------------------------------------------------*/
$siteblue: #2f80ab;
$sitegold: #f9bc33;
$sitegreen: #00833c;
$siteltgreen: #a2ba47;
$siteltblue: #63c3d1;
$sitepurple: #c24f97;
$sitegrey: #f4f4f3;
$sitered: #f0423a;
$siteyellow: #fff200;
$siteerror: #f00;
$txtcolor: #333;
$footergrey: #575756;

$max_width: 1200px;
$header_font_bold: 'ITCAvantGardeStd-Bold', 'Helvetica Neue', Arial, sans-serif;
$sub_header_font_bold: 'ITCAvantGardePro-Md', 'Helvetica Neue', Arial, sans-serif;
$sub_header_font_demi: 'ITCAvantGardePro-Demi', 'Helvetica Neue', Arial, sans-serif;
$body_font: "ITCAvantGardeStd", Helvetica, Arial, sans-serif;
$circular_font: "Circular", Helvetica, Arial, sans-serif;

//Breakpoints
$small-mobile-breakpoint:  325px  !default;
$medium-mobile-breakpoint:  375px  !default;
$large-mobile-breakpoint:  640px  !default;
$tablet-breakpoint:  769px  !default;
$medium-breakpoint: 1025px !default;
$large-breakpoint:  1439px !default;
$desktop-breakpoint:  1600px !default;
$xlarge-breakpoint: 1920px !default;

$desktop-height-breakpoint: 760px;
$desktop-large-height-breakpoint: 920px;

/* Sample Media Queries
---------------------------------*/

//  @include small-mobile {}
//  @include small-mobile-portrait {}
//  @include small-mobile-landscape {}
//  @include large-mobile {}
//  @include large-mobile-portrait {}
//  @include large-mobile-landscape {}
//  @include tablet {}
//  @include tablet-portrait {}
//  @include tablet-landscape {}
//  @include desktop {}
//  @include desktop-only {}
//  @include large-desktop {}
//  @include xlarge-desktop {}
//  @include xlarge-desktop-only {}
//  @include xxlarge-desktop {}
//  @include xxlarge-desktop-only {}