.contact-form {
  margin: 0 auto;
  max-width: 1100px;

  h2 {
    font-size: 6rem;
    margin-bottom: 2em;
    text-align: center;

    @include tablet {
      font-size: 4.2rem;
    }

    @include large-mobile {
      font-size: 2.4rem;
    }
  }

  .flex-row {
    .input {
      width: 45%;
    }

    @include large-mobile {
      display: block;

      .input {
        width: 100%;
      }
    }
  }

  .error-message {
    color: $siteerror;
    font-family: $header_font_bold;
    font-size: 1.7rem;
    left: -12px;
    margin: 0 0 2em;
    position: relative;
  }

  .input {
    border-bottom: 2px solid #c3c3c3;
    margin-bottom: 8em;
    position: relative;

    @include tablet {
      margin-bottom: 6em;
    }

    @include large-mobile {
      margin-bottom: 4em;
    }
  }

  label {
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 10px;
    transition: 0.35s all ease;
  }

  label, input, select, textarea {
    color: #c3c3c3;
    font-family: $sub_header_font_demi;
    font-size: 2.4rem;
    letter-spacing: 1px;
    padding: 0;
    width: 100%;
    @include tablet {
      font-size: 2rem;
    }
    @include large-mobile {
      font-size: 1.4rem;
    }
  }

  input, select, .select {
    margin-bottom: 5px;
  }

  input, select {
    border: 0;
    color: #575756;
    font-weight: bold;

    &:focus, &:active {
      border: 0;
    }
  }

  .input.focus,
  .input.filled {
    label {
      font-size: 1.8rem;
      top: -28px;
      @include large-mobile {
        font-size: 3vw;
      }
    }
  }

  .input.focus {
    label {
      color: $siteltblue;
    }
  }

  .input.error {
    border-color: $siteerror;

    label {
      color: $siteerror;
    }
  }

  .textarea {
    border-bottom: 0;

    label {
      position: static;
    }

    textarea {
      border: 2px solid #c3c3c3;
      border-radius: 0;
      color: #575756;
      font-weight: bold;
      height: 144px;
      padding: 6px 12px;
    }

    &.error {
      textarea {
        border-color: $siteerror;
      }
    }
  }

  .submit {
    input {
      color: $siteltblue;
      font-size: 4.8rem;
      height: auto;
      line-height: 1;
      text-transform: lowercase;

      &.disabled {
        color: $sitegrey;
        pointer-events: none;
      }
    }

    @include large-mobile {
      input {
        font-size: 3.6rem;
      }
    }
  }
}

.about .form-success {
  margin-bottom: 12em;
  text-align: center;

  h1 {
    color: $siteltblue;
    font-size: 6.4rem;
    margin: 0 0 1.5em;
  }

  p {
    font-size: 2.9rem;
    margin: 0 auto;
    max-width: 768px;
  }
}

.select {
  position: relative;
}

.select select {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border-radius: 0;
  margin: 0;
  outline: none;
  padding: 0;
  width: 100%;
}

.select::after {
  background: url('../images/about/select-arrow.png') no-repeat center;
  background-size: 14px 13px;
  content: '';
  height: 13px;
  margin-top: -6px;
  pointer-events: none;
  position: absolute;
  right: 5%;
  top: 50%;
  width: 14px;
  z-index: 2;
  .ie & {
    display: none;
  }
}

.select option {
  font-weight: normal;
}

x:-o-prefocus, .select::after {
  display: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
  .select select::-ms-expand {
    display: none;
  }
  .select select:focus::-ms-value {
    background: transparent;
    color: #222;
  }
}

@-moz-document url-prefix() {
  .select {
    overflow: hidden;
  }
  .select select {
    width: 120%;
    width: -moz-calc(100% + 3em);
    width: calc(110%);
  }
  
}

.select select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}