.featured-work {
  height: inherit;

  .section-title {
    margin-bottom: 30px;

    @include large-mobile {
      padding: 0 10px;
    }

    h1 {
      color: #ffffff;
      font-size: 10rem;
      text-align: left;
      float: left;

      @include desktop {
        font-size: 8.5rem;
      }

      @include desktop-small {
        font-size: 8rem;
      }

      @include tablet {
        font-size: 5.5rem;
      }

      @include large-mobile {
        font-size: 4.2rem;
        margin-bottom: 0;
      }

      @include medium-mobile {
        font-size: 3.6rem;
      }
    }

    .all-work-button, .all-work {
      float: right;
      margin-top: 25px;

      @include desktop {
        margin-top: 16px;
      }

      @include desktop-small {
        margin-top: 16px;
      }
    }

    .all-work-button {
      background-color: $sitepurple;
      color: #fff;
      display: block;
      float: right;
      font-family: $sub_header_font_demi;
      font-size: 2.6rem;
      height: 55px;
      line-height: 58px;
      text-align: center;
      vertical-align: middle;
      width: 210px;

      @include desktop-small {
        clear: both;
        float: none;
        margin: 0 auto 1em;
      }

      @include large-mobile {
        font-size: 1.8rem;
        height: 38px;
        line-height: 40px;
        width: 142px;
      }
    }

    .all-work {
      width: inherit;
      font-size: 1.2em;
      padding-top: 0;
      margin-left: 40px;

      &:before {
        left: -80px;
        top: 10px;
        width: 70px;
        height: 3px;
      }

      @include large-mobile {
        font-size: 1.6rem;
        margin-left: 0;

        &:before {
          background-color: $sitepurple;
          left: -60px;
          width: 50px;
        }
      }
    }
  }

  .flex-container {
    padding: 12rem 0;

    @include large-mobile {
      padding: 4rem 0;
    }
  }

  .all-work {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 20%;
    text-align: center;
    padding-top: 112px;
    color: #ffffff;
    font-size: 1.4em;
    @include large-mobile {
      width: 45%;
    }
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 20px;
      background-color: $siteltblue;
      width: 3px;
      height: 80px;
    }
  }
}