.about {
  @include small-footer();

  h1 {
    font-size: 8em;
    margin-bottom: 40px;

    @include large-mobile {
      font-size: 15vw;
    }
  }

  .masthead {
    background-image: url('../images/about/about-bg.png');
    h1 {
      text-transform: none;
    }
  }

  .section-nav {
    &:after{
      left: 19%;
    }

    @include tablet {
      font-size: 1.4rem;
    }
  }
  
  .about-intro {
    align-items: center;
    display: flex;
    height: inherit;
    justify-content: center;
    padding: 12rem 0;

    @include large-mobile {
      padding: 6rem 0;
      text-align: left;
    }

    h2 {
      color: #575756;
      font-size: 7.2rem;
      line-height: 1.35;
      margin-bottom: 1em;

      @include tablet {
        font-size: 5rem;
      }

      @include large-mobile {
        font-size: 3.8rem;
      }

      @include medium-mobile {
        font-size: 3.2rem;
      }
    }

    p {
      color: #575756;
      font-size: 2.6rem;
      line-height: 1.5;

      @include large-mobile {
        font-size: 1.8rem;
      }
    }
  }

  .introducing {
    align-items: center;
    background-image: url('../images/about/introducing-bg.png');
    background-size: cover;
    height: auto;
    padding: 12em 0;

    @include large-mobile {
      padding: 6em 0;
    }

    .flex-container {
      @include large-mobile {
        padding: 0 0 0 5%;
        width: auto;
      }
    }

    h3 {
      color: #ffffff;
      display: inline;
      font-size: 8rem;
      line-height: 1.3;
      position: relative;
      top: -30px;

      @include large-mobile {
        font-size: 5rem;
      }
    }

    .introducing-content {
      background-color: #fff;
      float: right;
      padding: 80px 100px;
      width: 800px;

      @include tablet {
        width: 100%;
        padding: 45px 25px;
      }
    }

    p {
      color: $footergrey;
      font-size: 2rem;
      line-height: 2;
      text-align: left;

      @include large-mobile {
        font-size: 1.5rem;
      }
    }
  }

  .statistics {
    align-items: center;
    background-color:  rgba(18, 18, 18, 0.75);
    background-image: url('../images/about/statistics-bg.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12rem 0;
    height: inherit;

    .flex-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: auto;
      justify-content: center;
      min-height: 100%;

      h1 {
        color: white;
      }
    }

    h1 {
      font-size: 12rem;

      @include desktop {
        font-size: 10rem;
      }

      @include tablet {
        font-size: 9rem;
      }

      @include tablet {
        font-size: 5rem;
      }
    }

    ul {
      display: flex;
      justify-content: space-between;
      margin: 0;
      text-align: center;
      text-transform: uppercase;
      width: 100%;

      li {
        color: #a6a6a6;
        font-size: 1.8rem;
      }

      strong {
        color: $siteltgreen;
        display: block;
        font-family: $header_font_bold;
        font-size: 8rem;
        line-height: 1.15em;
      }
    }

    @include desktop {
      ul {
        li {
          font-size: 1.5rem;
        }

        strong {
          font-size: 6rem;
        }
      }
    }

    @include desktop-small {
      ul {
        flex-wrap: wrap;
        justify-content: center;

        li {
          margin-bottom: 4rem;
          width: 50%;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    @include large-mobile {
      padding: 75px 0;

      h1 {
        text-align: left;
      }

      ul {
        li {
          font-size: 1.2rem;
        }

        strong {
          font-size: 4.5rem;
        }
      }
    }
  }

  .history {
    background-image: url('../images/about/history-section-bg.png');
    background-size: cover;
    height: auto;
    padding: 15em 0;

    @include tablet {
      height: inherit;
      padding: 10rem 0;
    }

    @include large-mobile {
      padding: 6rem 0;

      .offset-by-two.columns {
        width: 100%;
      }
    }

    h1 {
      color: $siteblue;
      font-size: 12rem;
      line-height: 1;
      text-align: center;
      border-bottom: 5px solid #a8a8a8;
      padding-bottom: 20px;

      @include tablet {
        font-size: 9rem;
      }

      @include tablet {
        font-size: 5rem;
        display: inline-block;
      }
    }

    p {
      color: $footergrey;
      font-size: 2rem;
      line-height: 2;
      text-align: left;

      @include large-mobile {
        font-size: 1.5rem;
      }
    }
  }
}

.about.services {
  .about-intro {
    display: block;
    position: relative;
    overflow: visible;

    .column, .columns {
      @include tablet {
        width: 100%;
        margin-left: 0;
      }
    }

    h2 {
      color: #575756;
      font-size: 5rem;
      font-weight: 700;
      line-height: 1.2;
      text-align: left;
      margin-bottom: 0;

      @include large-mobile {
        font-size: 3.8rem;
        margin-bottom: 1em;
      }

      @include medium-mobile {
        font-size: 3.2rem;
      }
    }

    p {
      color: rgba(87, 87, 86, 0.7);
      font-size: 2rem;
      line-height: 1.7;
      text-align: left;

      @include large-mobile {
        font-size: 1.5rem;
      }
    }

    &:after {
      content: '';
      background-image: url(../images/about/dots.png);
      background-position: left top;
      width: 40%;
      height: 40%;
      position: absolute;
      bottom: -25%;
      left: 0;
      z-index: 1;

      @include large-mobile{
        display: none;
      }
    }
  }

  .listed {
    background-color: $siteblue;
    background-image: url('../images/about/listed-bg.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 375px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 140px 0;

    .skills {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
      margin-left: 15%;
      margin-top: 20px;
      width: 60%;

      & > li {
        color: white;
        margin-bottom: 50px;
        padding-right: 10px;
        width: 25%;

        h5 {
          font-family: $sub_header_font_demi;
        }

        ul {
          margin: 0;
        }
      }
    }

    @include desktop-large {
      background-size: 250px auto;
    }

    @include desktop-small {
      background-size: 200px auto;
      padding: 80px 0;

      .skills {
        & > li {
          width: 50%;

        }
      }
    }

    @include large-mobile {
      background-image: none;
      padding: 40px 0;

      .skills {
        margin: 0 auto;
        width: 95%;

        & > li {
          margin-bottom:2rem;

          h5 {
            font-size: 2rem;
          }

          ul {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

.about.management {
  .about-intro {
    background-image: url('../images/about/dots.png');
    background-repeat: no-repeat;
    background-position: top left;
    height: inherit;
    padding: 6rem 0 4rem;

    h2 {
      text-align: left;
      font-size: 6rem;

      @include large-mobile {
        font-size: 4.2rem;
        margin-bottom: 0.5em;
      }
    }

    p {
      font-size: 20px;
      text-align: left;

      @include large-mobile {
        font-size: 4vw;
        line-height: 1.7;
      }
    }
  }

  ul.tabs {
    margin-bottom: 6em;

    @include large-mobile {
      margin-bottom: 3em;
    }
  }

  nav.toggle {
    font-family: $header_font_bold;
    width: 100%;
    margin-bottom: 4em;

    ul {
      text-align: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    li {
      display: inline-block;
      margin: 0 10px
    }

    a {
      background-color: rgba(99, 195, 209, .3);
      border: 4px solid rgba($siteltblue, 0.75);
      color: #fff;
      display: block;
      font-size: 4.8rem;
      line-height: 1;
      padding: 0.6em 40px 0.35em;
      position: relative;

      &:after {
        border: 16px solid transparent;
        border-top-color: $siteltblue;
        content: '';
        display: block;
        height: 0;
        left: 50%;
        margin-left: -16px;
        margin-top: 4px;
        opacity: 0;
        position: absolute;
        top: 100%;
        width: 0;
      }

      &, &:after {
        transition: 0.25s all ease;
      }

      @include desktop-only {
        font-size: 2.8rem;
      }

      @include tablet {
        font-family: $sub_header_font_demi;
        padding: 0.6em 30px 0.35em;
        font-size: 1.7rem;
      }
    }

    li.current a, a:hover {
      background-color: $siteltblue;
      border-color: $siteltblue;
    }

    li.current a {
      &:after {
        opacity: 1;
      }
    }
  }

  .leadership-grid {
    background-color: $siteblue;
    background-image: url('../images/about/leaders-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: inherit;
    padding: 12rem 0;
    padding-bottom: 150px;

    @include large-mobile {
      padding: 6rem 0;
    }

    h1 {
      color: white;

      @include large-mobile {
        font-size: 5rem;
      }
    }
  }
}

.about.contact {
  .headquarters {
    background-color: $sitegold;
    color: #fff;
    padding: 8em 0;

    .flex-container {
      align-items: flex-end;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    h3 {
      font-family: $sub_header_font_bold;
      font-size: 1.6rem;
      letter-spacing: 3px;
      margin-bottom: 3em;
      text-transform: uppercase;
      width: 100%;

      &:after {
        background-color: #fff;
        content: '';
        display: block;
        height: 3px;
        margin-top: 1em;
        opacity: 0.6;
        width: 50px;
      }
    }

    .contact-methods {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      width: 75%;

      li {
        break-inside: avoid;
        font-size: 1.4rem;
        letter-spacing: 3px;
        margin: 0;
        text-transform: uppercase;

        strong {
          display: block;
          font-family: $sub_header_font_bold;
          font-size: 1.8rem;
          letter-spacing: 0;
          text-transform: none;
        }
      }
    }

    .social-icons {
      margin-bottom: 0;

      li {
        display: inline-block;
        margin-left: 22px;

        &:first-child {
          margin-left: 0;
        }
      }

      a {
        opacity: 1;
      }
    }

    @include desktop-large {
      .flex-container {
        display: block;
      }

      .contact-methods {
        margin-bottom: 2em;
        width: 100%;
      }
    }

    @include desktop-small {
      .contact-methods {
        li {
          strong {
            font-size: inherit;
          }
        }
      }
    }

    @include large-mobile {
      padding: 4em 0;

      h3 {
        margin-bottom: 1.5em;
      }

      .contact-methods {
        display: block;

        li {
          margin-bottom: 1em;
        }
      }
    }
  }
}