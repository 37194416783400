.section-contacts {
  background: $txtcolor url('../images/about/contact-bg.jpg') no-repeat left top;
  background-size: cover;
  color: #fff;
  padding: 8em 0 4em;

  .flex-container {
    max-width: 1400px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      display: flex;
      margin-bottom: 4em;
      width: 50%;
    }
  }

  h3 {
    font-family: $sub_header_font_bold;
    font-size: 1.6rem;
    letter-spacing: 3px;
    margin: 0 32px 0 0;
    position: relative;
    text-align: right;
    text-transform: uppercase;
    width: 200px;

    &:after {
      background-color: $siteltgreen;
      content: '';
      display: block;
      height: 3px;
      margin-top: 0.75em;
      position: absolute;
      right: 0;
      top: 20px;
      width: 50px;
    }
  }

  dl {
    @include clearfix();
    margin: 0;

    dt, dd {
      float: left;
      margin-bottom: 0.5em;
    }

    dt {
      clear: left;
      font-size: 1.4rem;
      letter-spacing: 2px;
      text-transform: uppercase;
      width: 100px;
    }

    dd {
      clear: right;
      font-family: $sub_header_font_demi;
      font-size: 1.5rem;
      margin: 0;
    }
  }

  @include desktop-large {
    ul {
      li {
        display: block;
      }
    }

    h3 {
      margin: 0 0 1.25em;
      text-align: left;
      width: 100%;

      &:after {
        position: static;
      }
    }
  }

  @include large-mobile {
    padding: 4em 0;

    ul {
      display: block;

      li {
        margin-bottom: 2.5em;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    dl {
      dt, dd {
        float: none;
      }

      dt {
        margin-bottom: 0;
      }

      dd {
        margin-bottom: 1em;
      }
    }
  }

  @include small-mobile {
    dl {
      dd {
        font-size: 1.3rem;
      }
    }
  }
}