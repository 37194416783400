.life {
  p {
    color: #878787;
    font-size: 1.8rem;
    line-height: 1.5;
    text-align: left;
    @include tablet {
      font-size: 1.5rem;
    }
  }
  .masthead {
    background-image: url('../images/about/life-bg.png');
  }
  .about-intro {
    display: block;
    height: inherit;
    padding: 12rem 0;
    @include large-mobile {
      padding: 6rem 0;
    }
    h2 {
      color: $sitepurple;
      font-size: 6rem;
      margin-bottom: 1em;
      text-align: left;
      @include tablet {
        font-size: 4.2rem;
      }
      @include large-mobile {
        font-size: 3rem;
      }
    }
    p {
      color: $footergrey;
      font-size: 2rem;
      text-align: left;
      @include tablet {
        font-size: 1.5rem;
      }
    }
    img {
      margin-top: 6rem;
      width: 100%;

      @include large-mobile {
        margin-top: -6rem;
      }
    }

    .ten.columns {
      column-count: 2;
      column-gap: 4em;

      p {
        break-inside: avoid;
      }
    }

    @include desktop-small {
      .ten.columns {
        column-count: 1;
        column-gap: 0;
      }
    }
  }
  .meetings {
    background-color:  #2b2b2b;
    background-image: url('../images/about/meeting-bg.png');
    background-repeat: no-repeat;
    background-position: top center;
    height: inherit;
    padding: 10rem 0;

    @include large-mobile {
      padding: 6rem 0;
    }

    h1 {
      font-size: 8.5rem;
      color: #fff;
      @include large-mobile {
        font-size: 4.2rem;
      }
      span {
        color: $sitegold;
      }
    }
    ul {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      li.tile {
        background-color:  #ffffff;
        width: 100%;
        margin-bottom: 5rem;
        padding: 80px;
        @include large-mobile {
          margin-bottom: 3rem;
          padding: 20px;
        }
        h4 {
          color: $siteblue;
          font-size: 2.2rem;
          line-height: 1.35;
          text-align: left;
        }
        .date {
          color: #878787;
          font-size: 1.4rem;
          text-align: left;
          letter-spacing: .2em;
          text-transform: uppercase;
        }
        strong {
          font-family: $header_font_bold;
        }

        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .webinar-heading {
    hgroup {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;
    }

    h2 {
      color: $sitegold;
      font-size: 10rem;
      margin-bottom: 0;
    }

    h3 {
      color: #fff;
      font-size: 4rem;
    }

    .richtext {
      margin: 2rem 0 6rem;
      padding-left: 85px;
    }

    p {
      color: #fff;
      font-size: 2rem;
      line-height: 1.75;
    }

    @include desktop-large {
      hgroup {
        display: block;
      }
    }

    @include tablet {
      h2 {
        font-size: 6rem;
      }

      h3 {
        font-size: 3rem;
      }

      .richtext {
        padding-left: 0;
      }
    }

    @include large-mobile {
      h2 {
        font-size: 3rem;
      }

      h3 {
        font-size: 2rem;
      }

      p {
        font-size: 1.5rem;
      }
    }
  }

  .exchange {
    background-image: url('../images/about/exchange-bg.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: inherit;
    padding: 12rem 0;

    @include large-mobile {
      padding: 6rem 0;
    }

    h3 {
      color: rgba(87, 87, 86, 1);
      font-size: 8rem;
      line-height: 1.35;
      text-align: left;
      border-bottom: 5px solid #a2ba47;
      padding-bottom: 4rem;
      margin-bottom: 4rem;
      @include tablet {
        display: inline-block;
        font-size: 4.2rem;
        padding-bottom: 20px;
      }
    }
    h5 {
      color: rgba(87, 87, 86, 1);
      font-size: 4.5rem;
      line-height: 1.35;
      text-align: left;
      @include tablet {
        font-size: 2.4rem;
      }
    }
  }
}
