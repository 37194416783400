.leader-single {
  .leader {
    background-image: url('../images/about/leaders-bg.png');
    color:  #ffffff;
    .person{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 25px;
      position: relative;
      figure {
        width: 125px;
        height: 125px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0;
        margin-right: 40px;
        @include large-mobile {
          position: absolute;
          right: 0;
          bottom: -130px;
        }
        @include small-mobile {
          margin-right: 10px;
        }
        img {
          height: auto;
          min-height: 100%;
          width: 100%;
        }
      }
      .name {

        h2 {
          font-size: 5rem;
          line-height: 1;
          letter-spacing: 0.01em;
          margin: 0;

        }
        span {
          font-family: $body_font;
          font-size: 16px;
          line-height: 1.6;
          text-align: left;
          text-transform: uppercase;
          letter-spacing: 0.15em;
        }

        @include large-mobile {
          h2 {
            font-size: 4rem;
          }
        }
      }
    }

    a {
      position: relative;
      font-family: $body_font;
      color: #fff;
      font-size: 14px;
      line-height: 25px;
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      &:after {
        content: '';
        background-color: $siteltblue;
        width: 70%;
        height: 3px;
        position: absolute;
        bottom: -15PX;
        left: 0;
      }
    }
  }
  .leader-info {
    height: inherit;
    padding: 100px 0;
    p {
      font-family: $body_font;
      color: $footergrey;
      font-size: 16px;
      font-weight: 300;
      line-height: 25px;
      text-align: left;
      &.head{
        color: $sitepurple;
        text-transform: uppercase;
      }
    }
  }
}