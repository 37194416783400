footer {
  background-color: #222;
  color: #a7a7a7;
  padding: 4.8rem;

  .footer-wrapper {
    @include max_width(1200px);
    height: 164px;
    position: relative;
  }

  a {
    color: inherit;

    &:hover {
      color: #fff;
    }
  }

  .social-icons {
    display: flex;
    margin: -16px 0 0;
    position: absolute;
    top: 50%;

    li {
      margin: 0 48px 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .logos {
    left: 50%;
    margin-left: -120px;
    position: absolute;
    top: 0;
    width: 240px;

    a {
      display: block;
    }

    .icom-logo {
      margin: 0 auto 3.5rem;
      width: 100px;

      img {
        height: auto;
        width: 100%;
      }
    }

    .morrison-logo {
      font-size: 1.2rem;
      letter-spacing: 1px;
      text-align: center;

      img {
        height: auto;
        margin-left: 5px;
        position: relative;
        top: 4px;
        width: 113px;
      }
    }
  }

  .disclaimers {
    margin-top: -25px;
    position: absolute;
    right: 0;
    top: 50%;

    ul {
      display: flex;
      justify-content: space-between;
      margin: 0 0 1rem;

      li {
        margin: 0;
      }
    }

    a {
      font-size: 1.4rem;
    }

    p {
      color: #393939;
      font-size: 1.1rem;
      letter-spacing: 1px;
      margin: 0;
      text-transform: uppercase;
    }
  }

  @include tablet {
    padding: 6rem 0;

    .footer-wrapper {
      height: auto;
    }

    .social-icons, .logos, .disclaimers {
      left: 0;
      position: relative;
      right: auto;
      top: 0;
    }

    .logos {
      margin: 0 0 4rem 0;
      text-align: center;
      width: 100%;

      .morrison-logo {
        border-bottom: 1px solid #393939;
        border-top: 1px solid #393939;
        display: inline-block;
        padding: 1.6rem 0;
      }
    }

    .social-icons {
      margin: 0 auto 4rem;
      width: 150px;

      .facebook, .twitter, .linkedin {
        height: 28px;
        width: 28px;
      }

      li {
        margin-right: 32px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .disclaimers {
      margin: 0 auto;
      text-align: center;
      width: 242px;

      a {
        font-size: 1.1rem;
      }

      p {
        font-size: 0.9rem;
      }
    }
  }
}