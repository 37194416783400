@mixin clearfix() {
  display: block;
  .ie7 & {
    display: inline-block;
  }
  &:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
  html[xmlns] & {
    display: block;
  }
  * html & {
    height: 1%;
  }
}

@mixin max_width($width) {
  margin: 0 auto;
  max-width: $width;
  width: 95%;
}

@mixin vertical_align() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin small-footer() {
  footer .bottom {padding-top: 0;}
  .second-nav {display: none; }
}

@mixin image_replace($image, $width, $height, $x: left, $y: top) {
  background: url("../images/#{$image}") no-repeat;
  background-position: $x $y;
  display: block;
  height: $height;
  overflow: hidden;
  position: relative;
  text-indent: 100%;
  width: $width;
  white-space: nowrap;
}

/* Media Queries
----------------------------------------------------------------------------------------------------*/

// Media Queries
$screen: "only screen" !default;
$landscape: " (orientation: landscape)" !default;
$portrait: " (orientation: portrait)" !default;

$retina: (
  "#{$screen} and (-webkit-min-device-pixel-ratio: 2)",
  "#{$screen} and (min--moz-device-pixel-ratio: 2)",
  "#{$screen} and (-o-min-device-pixel-ratio: 2/1)",
  "#{$screen} and (min-device-pixel-ratio: 2)",
  "#{$screen} and (min-resolution: 192dpi)",
  "#{$screen} and (min-resolution: 2dppx)"
);


@mixin small-mobile {
  @media #{$screen} and (max-width:#{$small-mobile-breakpoint}) {
    @content;
  }
}
@mixin small-mobile-portrait {
  @media #{$screen} and (max-width:#{$small-mobile-breakpoint}) and #{$portrait} {
    @content;
  }
}
@mixin small-mobile-landscape {
  @media #{$screen} and (max-width:#{$large-mobile-breakpoint}) and #{$landscape} {
    @content;
  }
}

@mixin medium-mobile {
  @media #{$screen} and (max-width:#{$medium-mobile-breakpoint}) {
    @content;
  }
}
@mixin medium-mobile-portrait {
  @media #{$screen} and (max-width:#{$large-mobile-breakpoint}) and #{$portrait} {
    @content;
  }
}
@mixin medium-mobile-landscape {
  @media #{$screen} and (max-width:#{$tablet-breakpoint}) and #{$landscape} {
    @content;
  }
}

@mixin large-mobile {
  @media #{$screen} and (max-width:#{$large-mobile-breakpoint}) {
    @content;
  }
}
@mixin large-mobile-portrait {
  @media #{$screen} and (max-width:#{$large-mobile-breakpoint}) and #{$portrait} {
    @content;
  }
}
@mixin large-mobile-landscape {
  @media #{$screen} and (max-width:#{$tablet-breakpoint}) and #{$landscape} {
    @content;
  }
}

@mixin tablet {
  @media #{$screen} and (max-width:#{$tablet-breakpoint}) {
    @content;
  }
}
@mixin tablet-portrait {
  @media #{$screen} and (max-width:#{$tablet-breakpoint}) and #{$portrait} {
    @content;
  }
}
@mixin tablet-landscape {
  @media #{$screen} and (max-width:#{$tablet-breakpoint}) and #{$landscape} {
    @content;
  }
}

@mixin desktop-small {
  @media #{$screen} and (max-width:#{$medium-breakpoint}) {
    @content;
  }
}

@mixin desktop {
  @media #{$screen} and (min-width:#{$medium-breakpoint}) {
    @content;
  }
}

@mixin desktop-large-height {
  @media #{$screen} and (max-height: #{$desktop-large-height-breakpoint}) {
    @content;
  }
}

@mixin desktop-height {
  @media #{$screen} and (max-height:#{$desktop-height-breakpoint}) {
    @content;
  }
}

@mixin desktop-only {
  @media #{$screen} and (min-width:#{$medium-breakpoint}) and (max-width:#{$large-breakpoint - 1px}) {
    @content;
  }
}

@mixin desktop-large {
  @media #{$screen} and (max-width:#{$large-breakpoint}) {
    @content;
  }
}

@mixin large-desktop {
  @media #{$screen} and (min-width:#{$desktop-breakpoint}) {
    @content;
  }
}
@mixin xlarge-desktop {
  @media #{$screen} and (min-width:#{$large-breakpoint}) {
    @content;
  }
}
@mixin xlarge-desktop-only {
  @media #{$screen} and (min-width:#{$large-breakpoint}) and (max-width:#{$xlarge-breakpoint - 1px}) {
    @content;
  }
}
@mixin xxlarge-desktop {
  @media #{$screen} and (min-width:#{$xlarge-breakpoint}) {
    @content;
  }
}