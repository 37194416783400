@font-face {
  font-family: 'ITCAvantGardeStd-Bold';
  src: url('../fonts/ITCAvantGardeStd-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/ITCAvantGardeStd-Bold.otf')  format('opentype'),
	     url('../fonts/ITCAvantGardeStd-Bold.woff') format('woff'), url('../fonts/ITCAvantGardeStd-Bold.ttf')  format('truetype'), url('../fonts/ITCAvantGardeStd-Bold.svg#ITCAvantGardeStd-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ITCAvantGardeStd';
  src: url('../fonts/ITCAvantGardeStd-Bk.eot?#iefix') format('embedded-opentype'),  url('../fonts/ITCAvantGardeStd-Bk.otf')  format('opentype'),
	     url('../fonts/ITCAvantGardeStd-Bk.woff') format('woff'), url('../fonts/ITCAvantGardeStd-Bk.ttf')  format('truetype'), url('../fonts/ITCAvantGardeStd-Bk.svg#ITCAvantGardeStd-Bk') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ITCAvantGardePro-Md';
  src: url('../fonts/ITCAvantGardePro-Md.eot?#iefix') format('embedded-opentype'),  url('../fonts/ITCAvantGardePro-Md.otf')  format('opentype'),
	     url('../fonts/ITCAvantGardePro-Md.woff') format('woff'), url('../fonts/ITCAvantGardePro-Md.ttf')  format('truetype'), url('../fonts/ITCAvantGardePro-Md.svg#ITCAvantGardePro-Md') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ITCAvantGardePro-Demi';
  src: url('../fonts/ITCAvantGardePro-Demi.eot?#iefix') format('embedded-opentype'),  url('../fonts/ITCAvantGardePro-Demi.otf')  format('opentype'),
	     url('../fonts/ITCAvantGardePro-Demi.woff') format('woff'), url('../fonts/ITCAvantGardePro-Demi.ttf')  format('truetype'), url('../fonts/ITCAvantGardePro-Demi.svg#ITCAvantGardePro-Demi') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Circular';
  src: url('../fonts/CircularSpotifyTxT-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/CircularSpotifyTxT-Bold.otf')  format('opentype'),
	     url('../fonts/CircularSpotifyTxT-Bold.woff') format('woff'), url('../fonts/CircularSpotifyTxT-Bold.ttf')  format('truetype'), url('../fonts/CircularSpotifyTxT-Bold.svg#CircularSpotifyTxT-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
