grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  .grid-item-leader {
    flex-grow: 0;
    transition: all .2s ease-in-out;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
    width: 25%;
    @include large-mobile {
      width: 33.33%;
    }
    @include medium-mobile {
      width: 50%;
    }
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
    &:hover { transform: scale(1.05);}
    &:hover>.details { opacity: 1;}
    .details {
      background-color: rgba(255, 255, 255, 0.85);
      position: absolute;
      width: 100%;
      height: 100%;
      color: #353535;
      transition: all .4s ease-in-out;
      opacity: 0;
      top: 0;
      left: 0;
      .flex-row{
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-content: flex-end;
        margin-left: 25px;
        padding-bottom: 30px;
        p {
          text-align: left;
          color: #474747;
          font-size: 12px;
          line-height: 16px;
          text-align: left;
          margin-bottom: 0;
        }
        .name {
          font-family: $sub_header_font_demi;
          color: #a2ba47;
          font-size: 24px;
          line-height: 30px;
        }
        .title {
          text-transform: uppercase;
          margin-left: 10px;
        }
      }
    }
  }
  .grid-item-member {
    align-items: flex-start;
    flex-grow: 0;
    transition: all .2s ease-in-out;
    background-color: #fff;
    background-size: inherit;
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px solid rgb(244, 244, 243);
    position: relative;
    width: 25%;
    @include large-mobile {
      width: 33.33%;
    }
    @include medium-mobile {
      width: 50%;
    }
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
    &:hover {
      transform: scale(1.05);
    }
    &:last-child {
      flex-grow: 0;
    }
    img {
      @include vertical_align();
      height: auto;
      left: 50%;
      max-height: 80%;
      max-width: 80%;
      transform: translate(-50%, -50%);
      width: auto;
    }
  }
  .grid-item {
    flex-grow: 0;
    transition: all .2s ease-in-out;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    width: 25%;
    @include large-mobile {
      width: 33.33%;
    }
    @include medium-mobile {
      width: 50%;
    }
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
    &:hover { transform: scale(1.05);}
    &:hover>.details { opacity: 1;}
    .details {
      align-items: center;
      background-color: rgba(255, 255, 255, 0.85);
      color: #353535;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-end;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: all .4s ease-in-out;
      width: 100%;

      .row{
        width: 90%;
        margin-bottom: 5%;
        h4 {
          font-family: $header_font_bold;
          font-size: 3rem;
          line-height: 1;
          margin-bottom: .5em;
          @include desktop {
            font-size: 2rem;
          }
        }
        span {
          position: relative;
          font-family: $body_font;
          text-transform: uppercase;
          font-size: 1.5rem;
          margin-left: 40px;
          &:before {
            content: '';
            position: absolute;
            left: -30px;
            top: 4px;
            background-color: $sitegold;
            width: 25px;
            height: 3px;
          }
          @include desktop {
            font-size: 1.4rem;
          }
        }
        .right-btn {
          background-color: $sitegold;
          background-image: url('../images/right-arrow.png');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 25%;
          border-radius: 50%;
          height: 5vw;
          margin-top: 10px;
          overflow: hidden;
          text-indent: 500%;
          white-space: nowrap;
          width: 5vw;
          @include large-mobile {
            width: 6vw;
            height: 6vw;
          }
        }
      }
    }
  }
}