.media {
  .section-fill {
    color: $footergrey;
    height: auto;
    padding: 12rem 0;

    h1 {
      font-size: 4.8rem;
      margin-bottom: 8rem;
    }

    p {
      font-size: 2rem;
    }

    @include tablet {
      padding: 6rem 0;

      h1 {
        font-size: 2.4rem;
        margin-bottom: 4rem;
      }

      h2 {
        font-size: 2.4rem;
        margin-bottom: 4rem;
      }

      p {
        font-size: 1.5rem;
      }
    }
  }

  .media-intro {
    background-image: url('../images/about/dots.png');
    background-repeat: no-repeat;
    background-position: top left;

    h1 {
      color: #575756;
    }

    .ten.columns {
      column-count: 2;
      column-gap: 4em;
    }

    @include desktop-small {
      .ten.columns {
        column-count: 1;
        column-gap: 0;
      }
    }
  }

  .benefits {
    background: #ffba27 url('../images/brands/map.png') no-repeat center center;
    height: auto;
    padding: 12rem 0;

    .wrapper {
      @include max_width(1400px);
    }

    h1 {
      color: #fff;
      font-size: 6em;
      margin-bottom: 1em;
      text-align: center;
    }

    ul {
      @include max_width(1230px);
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      text-align: center;

      figure {
        height: 80px;
        margin: 0;
      }

      li {
        color: #716345;
        font-family: $sub_header_font_bold;
        font-size: 1.9rem;
        line-height: 1.35em;
        text-transform: uppercase;
      }
    }

    @include desktop-small {
      padding: 10rem 0;

      h1 {
        font-size: 4.2rem;
        text-align: left;
      }

      ul {
        flex-wrap: wrap;
        justify-content: center;

        li {
          font-size: 1.5rem;
          margin-bottom: 2em;
          width: 50%;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    @include large-mobile {
      padding: 6rem 0;

      h1 {
        font-size: 3.2em;
      }
    }
  }

  .process {
    h1 {
      color: #2d6a8a;
      font-size: 8rem;
      text-align: center;
    }

    @include tablet {
      h1 {
        font-size: 4.2rem;
        text-align: left;
      }
    }
  }

  .leadership-content {
    grid {
      justify-content: flex-start;
    }
  }

  .about-intro {
    background-image: url('../images/about/dots.png');
    background-repeat: no-repeat;
    background-position: top left;
  }

  .about-intro, .leadership-grid {
    h1 {
      font-size: 8em;
      margin-bottom: 40px;

      @include large-mobile {
        font-size: 5rem;
      }
    }

    h2 {
      text-align: left;
      font-size: 6rem;

      @include large-mobile {
        font-size: 4.2rem;
        margin-bottom: 0.5em;
      }
    }
  }

  .leadership-grid {
    background-color: $siteblue;
    background-image: url('../images/about/leaders-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: inherit;
    padding: 12rem 0;
    padding-bottom: 150px;

    @include large-mobile {
      padding: 6rem 0;
    }

    h1 {
      color: white;
    }
  }

  .team-grid {
    h1 {
      color: #2d6a8a;
      font-size: 12rem;
    }

    @include tablet {
      h1 {
        font-size: 8rem;
      }
    }

    @include large-mobile {
      h1 {
        font-size: 5rem;
      }
    }
  }
}