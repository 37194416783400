.masthead {
  background-color: $siteblue;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  height: 280px;
  letter-spacing: 0.07em;

  @include large-mobile {
    height: 200px;
  }

  .wrapper {
    @include max_width(1200px);
    @include vertical_align();
    position: relative;
    
    @include desktop-only {
      @include max_width(900px);
    }
    @include tablet {
      @include max_width(600px);
    }
  }
  hgroup {
    font-family: $header_font_bold;
    text-transform: lowercase;
    h1 {
      font-size: 8rem;
      letter-spacing: 0.01em;
      margin: 0;
      &:after {
        background-color: $siteltblue;
        content: '';
        display: inline-block;
        height: 0.2em;
        margin-left: 1%;
        width: 0.2em;
      }

      @include large-mobile {
        font-size: 5rem;
      }
    }
    p {
      color: #dadada;
      font-size: 3rem;
      line-height: 1.2;
      margin: 0 0 0 6%;
      position: relative;
      @include large-mobile {
        font-size: 2.2rem;
      }
      &:after {
        background-color: $siteltblue;
        content: '';
        display: block;
        height: 3px;
        margin-top: -3px;
        position: absolute;
        right: 104%;
        top: 50%;
        width: 75px;
      }
    }
  }
  hgroup.small {
    h1 {
      font-size: 8rem;
      line-height: 1em;
      &:after {
        margin-left: 10px;
      }
      @include desktop {
        font-size: 7rem;
      }
      @include tablet {
        font-size: 6rem;
      }
      @include large-mobile {
        font-size: 4.5rem;
      }
      @include medium-mobile {
        font-size: 3.8rem;
      }
    }
    p {
      color: #fff;
      font-family: $body_font;
      font-size: 1.4rem;
      letter-spacing: 0.25em;
      margin-bottom: 1em;
      text-transform: uppercase;
      &:after {
        right: 102%;
      }
    }
  }
  nav {
    font-family: $header_font_bold;
    margin-top: 4em;
    ul {
      text-align: center;
    }
    li {
      display: inline-block;
      margin: 0 10px
    }
    a {
      background-color: transparent;
      border: 4px solid rgba($siteltblue, 0.75);
      color: #fff;
      display: block;
      font-size: 4.8rem;
      line-height: 1;
      padding: 0.6em 40px 0.35em;
      position: relative;
      &:after {
        border: 16px solid transparent;
        border-top-color: $siteltblue;
        content: '';
        display: block;
        height: 0;
        left: 50%;
        margin-left: -16px;
        margin-top: 4px;
        opacity: 0;
        position: absolute;
        top: 100%;
        width: 0;
      }
      &, &:after {
        transition: 0.25s all ease;
      }
      @include desktop-only {
        font-size: 2.8rem;
      }
      @include tablet {
        font-family: $sub_header_font_demi;
        padding: 0.6em 30px 0.35em;
        font-size: 1.7rem;
      }
    }
    li.current a, a:hover {
      background-color: $siteltblue;
      border-color: $siteltblue;
    }
    li.current a {
      &:after {
        opacity: 1;
      }
    }
  }
}

body.agencies.index .masthead {
  background-image: url('../images/mastheads/agencies.jpg');
}

body.agencies.directory .masthead {
  background-image: url('../images/mastheads/directory.jpg');
}

body.agencies.leaders .masthead {
  background-image: url('../images/mastheads/leaders.jpg');
}

body.agencies.testimonials .masthead {
  background-image: url('../images/mastheads/testimonials.jpg');
}

body.brands.index .masthead {
  background-image: url('../images/mastheads/brands.jpg');
}

body.brands.work .masthead {
  background-image: url('../images/mastheads/work.jpg');
}

body.about.news-inner .masthead {
  background-image: url('../images/mastheads/article.jpg');
}

body.media .masthead {
  background-image: url('../images/mastheads/media.jpg');
}