.brands {
  .chart {
    background-color: #f4f4f3;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    height: inherit;
    padding: 12rem 0;
    position: relative;
    @include tablet {
      min-height: 675px;
    }
    @include desktop-only {
    }
    @include large-mobile {
      padding: 6rem 0;
    }
    h2 {
      color: #909090;
      font-size: 7.2rem;
      text-align: center;
      @include desktop {
        font-size: 6rem;
      }
      @include tablet {
        font-size: 5rem;
      }
      @include large-mobile {
        font-size: 4.2rem;
      }
      .yellow {
        color: #f3bb3b;
      }
    }
    figure {
      margin: 2em 20%;
      @include large-mobile {
        margin: 5%;
      }
    }
    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }
  .copy-section{
    color:  #575756;
    height: inherit;
    padding: 15rem 0;
    text-align: center;
    @include large-mobile {
      padding: 4rem 0;
      text-align: left;
    }
    .flex-container {
      font-size: 1.2em;
      max-width: 960px;
    }
    h3 {
      color: $sitepurple;
      font-size: 6rem;
      line-height: 1;
      @include large-mobile {
        font-size: 4rem;
      }
    }
    .callout {
      font-size: 1.9em;
      line-height: 1.5;
      @include large-mobile {
        font-size: 1.8rem;
      }
    }
    p {
      @include large-mobile {
        font-size: 1.5rem;
      }
    }
  }
  .featured-work {
    .section-title {
      h1 {
        color: $footergrey;

        @include desktop-small {
          float: none;
          text-align: center;
        }

        @include large-mobile {
          margin-bottom: 0.5em;
        }
      }
    }

    .all-work {
      color: #b4b5b4;
      @include large-mobile {
        margin: 0;
      }
      &:before {
        background-color: $sitepurple;
      }
    }
  }
  .processes{
    align-items: center;
    background-color: rgb(45, 106, 138);
    background-image: url('../images/brands/process-bg.png');
    background-position: top center;
    background-repeat: no-repeat;
    color: white;
    height: inherit;
    padding: 10rem 0;

    @include large-mobile {
      padding: 4rem 0;
    }

    .flex-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: inherit;
      max-width: 940px;
      @include large-mobile {
        align-items: flex-start; 
      }
    }
    h1 {
      font-size: 5em;

      @include large-mobile {
        font-size: 3rem;
      }
    }
    h6 {
      font-family: $sub_header_font_demi;
      font-size: 3rem;

      @include large-mobile {
        font-size: 1.8rem;
      }
    }
    p {
      font-size: 2rem;
      @include large-mobile {
        text-align: left;
        font-size: 1.5rem;
        opacity: .75;
      }
    }
    .bottom-hr {
      padding-bottom: 50px;
      border-bottom: 1px solid rgba(247, 247, 247, .25);
      margin-bottom: 60px;
      @include large-mobile {
        padding-bottom: 30px;
        margin-bottom: 30px;
      }
    }
    ul {
      margin-top: 4em;
      margin-bottom: 4em;
      text-align: center;
      @include large-mobile {
        display: flex;
        justify-content: space-between;
        margin: 0 0 2em 0;
      }
      li {
        display: inline-block;
        margin: 0 10px;
        @include large-mobile {
          width: 50%;
          flex-shrink: 1;
        }
      }
      a {
        font-family: $sub_header_font_bold;
        background-color: transparent;
        border: 4px solid rgba($siteltblue, 0.75);
        color: #fff;
        display: block;
        font-size: 3rem;
        line-height: 1.25;
        padding: 0.6em 40px 0.35em;
        position: relative;
        @include tablet {
          font-size: 2rem;
        }
        @include large-mobile {
          border: 2px solid rgba($siteltblue, 0.75);
          font-size: 1.8rem;
          padding: 0.6em 10% 0.35em;
        }
        @include small-mobile {
          font-size: 1.6rem;
        }
        &:after {
          border: 16px solid transparent;
          border-top-color: $siteltblue;
          content: '';
          display: block;
          height: 0;
          left: 50%;
          margin-left: -16px;
          margin-top: 4px;
          opacity: 0;
          position: absolute;
          top: 100%;
          width: 0;
          @include large-mobile {
            border-width: 8px;
            margin-top: 2px;
            margin-left: -8px;
          }
        }
        &, &:after {
          transition: 0.25s all ease;
        }
      }
      li.active a, a:hover {
        background-color: $siteltblue;
        border-color: $siteltblue;
      }
      li.active a {
        &:after {
          opacity: 1;
        }
      }
    }
    .slide {
      height: 0;
      opacity: 0;
      overflow: hidden;
      pointer-events: none;
      transition-delay: 0.75s;
      transition: 0.75s opacity ease;
      @include large-mobile {
        width: 95%;
      }
      &.active {
        height: auto;
        opacity: 1;
        pointer-events: auto;
        transition-delay: 0;
      }
    }
  }

  &.work {
    .featured-work {
      .flex-container {
        @include large-mobile {
          margin: 0 auto;
          width: 90%;
        }
      }
    }
  }
}

.brands.single {
  .masthead {
    hgroup {
      h1 {
        margin-top: 0.5em;
      }
      a {
        color: #fff;
        font-family: $body_font;
        font-size: 14px;
        letter-spacing: 0.1em;
        line-height: 25px;
        position: relative;
        text-align: left;
        text-transform: uppercase;
        &:after {
          background-color: $siteltblue;
          bottom: -15PX;
          content: '';
          height: 3px;
          left: 0;
          position: absolute;
          width: 70%;
        }
      }
    }
  }
  .single-work {
    height: inherit;
    .flex-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 9rem 0;

      @include large-mobile {
        padding: 6rem 0;
      }
    }
    .work-heading {
      border-bottom: 1px solid #575756;
      justify-content: flex-start;
      margin: 0 0 100px;
      width: 100%;
      @include large-mobile {
        flex-direction: column;
        margin-bottom: 40px;
        padding-bottom: 1em;
      }
      .head-items {
        margin-right: 80px;
        h6 {
          margin-bottom: 0px;
        }
        p, ul {
          font-size: 1.2em;
          line-height: 1.5;
          margin: 0.15em 0 0;
        }
        ul {
          li {
            display: inline;
            margin: 0;
            &:after {
              content: ',';
            }
            &:last-child:after {
              content: '';
            }
          }
        }
        &:first-child {
          margin-left: 4%;
        }
        @include large-mobile {
          margin-left: 4%;
        }
      }
    }
    .description {
      font-size: 2rem;
      padding: 0 4% 4em;
      width: 100%;

      @include large-mobile {
        font-size: 1.5rem;
        padding-bottom: 2em;
      }
    }
    .samples {
      width: 100%;
      img, .video-iframe {
        margin: 0 auto 6em;
        width: 92%;
      }
      img {
        display: block;
        height: auto;
      }
      .video-iframe {
        position: relative;
        &:after {
          content: '';
          display: block;
          padding-top: 56%;
        }
        iframe {
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
    h4, h6{
      color: $sitepurple;
      text-align: left;
    }
    p{
      color: #575756;
      text-align: left;
      line-height: 2;
    }
  }
}