nav.category-nav {
    margin-top: 0;
    margin-bottom: 4em;
    text-align: left;

    @include large-mobile {
        margin-bottom: 2em;
    }

    li {
        display: inline-block;
        margin: 0 -1px;
    }
    a {
        font-family: $sub_header_font_bold;
        border: 4px solid rgba($siteltgreen, 0.75);
        color: #b4b5b4;
        display: block;
        font-size: 3rem;
        line-height: 1;
        padding: 0.6em 40px 0.35em;
        position: relative;

        @include large-mobile-landscape {
            border-width: 3px;
            font-size: 2rem;
        }

        @include large-mobile {
            border-width: 2px;
            font-size: 1.5rem;
            padding: 0.75em 16px 0.65em;
        }

        @include medium-mobile {
            font-size: 1.4rem;
            padding-left: 12px;
            padding-right: 12px;
        }

        @include small-mobile {
            padding-left: 10px;
            padding-right: 10px;
        }

        &:after {
            border: 16px solid transparent;
            border-top-color: $sitepurple;
            content: '';
            display: block;
            height: 0;
            left: 50%;
            margin-left: -16px;
            margin-top: 3px;
            opacity: 0;
            position: absolute;
            top: 100%;
            width: 0;
            @include large-mobile {
                border-width: 8px;
                margin-top: 2px;
                margin-left: -8px;
            }
        }
        &,
        &:after {
            transition: 0.25s all ease;
        }
    }
    a:hover,
    li.current a {
        background-color: $sitepurple;
        border-color: $sitepurple;
        color: #fff;
    }
    li.current a {
        &:after {
            opacity: 1;
        }
    }
}