.richtext {
  b, strong {
    font-family: $sub_header_font_demi;
  }

  blockquote {
    margin-left: 44px;
    margin-right: 44px;

    p {
      margin-bottom: 0.25em;

      &:not(:last-child) {
        font-family: $header_font_bold;
        font-size: 2.4rem;
        position: relative;

        &:before, &:after {
          font-size: 8rem;
          line-height: 0;
          opacity: 0.2;
        }

        &:before {
          content: '“';
          display: block;
          margin-right: 8px;
          position: absolute;
          right: 100%;
          top: 0.5em;
        }

        &:after {
          content: '”';
          margin-left: 8px;
          position: relative;
          top: 0.35em;
        }
      }

      &:last-child {
        color: $siteltgreen;
        margin-bottom: 2em;
      }

      @include large-mobile {
        &:not(:last-child) {
          font-size: 1.8rem;

          &:before, &:after {
            font-size: 4rem;
          }
        }

        &:last-child {
          color: $siteltgreen;
          margin-bottom: 2em;
        }
      }
    }

    @include large-mobile {
      margin-left: 28px;
      margin-right: 0;
    }
  }

  ul, ol {
    margin-bottom: 2em;
    margin-left: 1em;

    ul, ol {
      font-size: 1em;
      margin-bottom: 0;
      margin-left: 2em;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    margin-left: 0;
  }
}