ul.tabs {
  margin-top: 0em;
  margin-bottom: 7em;
  text-align: center;

  @include large-mobile {
    margin-bottom: 3em;
  }

  li {
    display: inline-block;
    margin: 0;
  }
  a {
    font-family: $sub_header_font_bold;
    border: 4px solid rgba($siteltgreen, 0.75);
    color: #b4b5b4;
    display: block;
    font-size: 3rem;
    line-height: 1;
    padding: 0.6em 40px 0.35em;
    position: relative;
    @include large-mobile {
      border-width: 2px;
      font-size: 4vw;
    }

    &:after {
      border: 16px solid transparent;
      border-top-color: $sitepurple;
      content: '';
      display: block;
      height: 0;
      left: 50%;
      margin-left: -16px;
      margin-top: 3px;
      opacity: 0;
      position: absolute;
      top: 100%;
      width: 0;
      @include large-mobile {
        margin-top: 2px;
        margin-left: -8px;
        border-width: 8px;
      }
    }
    &, &:after {
      transition: 0.25s all ease;
    }
  }
  li.current a, a:hover {
    background-color: $sitepurple;
    border-color: $sitepurple;
    color: #fff;
  }
  li.current a {
    &:after {
      opacity: 1;
    }
  }
}

.region-filter {
    margin-bottom: 2em;

    ul {
        background-color: $sitepurple;
        height: 70px;
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        li {
            flex-shrink: 0;
            margin-bottom: 0;
            list-style-type: none;
            a {
                color: #ebcbde;
                line-height: 2.5em;
                &.active,
                &:hover {
                    color: #fff;
                }
            }
        }
    }

    @include tablet {
        position: relative;

        &:after {
            background: linear-gradient(135deg, rgba(194,79,151,0) 0%,rgba(194,79,151,0) 15%,rgba(194,79,151,1) 76%,rgba(194,79,151,1) 100%);
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
        }

        ul {
            flex-wrap: nowrap;
            height: 60px;
            justify-content: flex-start;
            overflow: scroll;
            white-space: nowrap;

            li {
                padding: 0 16px;

                &:last-child {
                  padding-right: 32px;
                }
            }
        }
    }
}