.section-directory {
  background-color: #292929;
  background-image: url('../images/brands/directory-bg.jpg');
  background-repeat: no-repeat;
  background-position:  center center;
  background-size: cover;
  color: white;
  height: 670px;

  @include tablet {
    height: auto;
    padding: 6rem 0;
  }

  .flex-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: inherit;
    max-width: 960px;
  }
  h1 {
    font-size: 6em;
    line-height: 2;
    @include tablet {
      font-size: 4.2rem;
    }
  }
  ul {
    margin-top: 0em;
    margin-bottom: 0em;
    text-align: center;
    @include tablet {
      display: flex;
      flex-direction: column;
    }
    li {
      display: inline-block;
      margin: 0 10px;
      @include tablet {
        margin: 10px;
      }
    }
    a {
      font-family: $sub_header_font_bold;
      background-color: transparent;
      border: 4px solid rgba($siteltgreen, 0.75);
      color: #fff;
      display: block;
      font-size: 3rem;
      line-height: 1;
      padding: 0.6em 40px 0.35em;
      position: relative;
      &:after {
        border: 16px solid transparent;
        border-top-color: $siteltgreen;
        content: '';
        display: block;
        height: 0;
        left: 50%;
        margin-left: -16px;
        margin-top: 4px;
        opacity: 0;
        position: absolute;
        top: 100%;
        width: 0;
      }
      &, &:after {
        transition: 0.25s all ease;
      }

      @include tablet {
        font-size: 2.4rem;
      }
    }
    a:hover {
      background-color: $siteltgreen;
      border-color: $siteltgreen;
    }
  }
}