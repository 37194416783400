.section-intro {
  align-items: center;
  display: flex;
  height: inherit;
  justify-content: center;
  padding: 15rem 0;

  @include desktop-small {
    padding: 8rem 0;
  }

  @include tablet {
    padding: 4rem 0;
  }

  .column, .columns {
    @include tablet {
      width: 100%;
      margin-left: 0;
    }
  }

  h2 {
    color: #575756;
    font-size: 4.8rem;
    line-height: 1.25em;

    @include desktop-small {
      font-size: 3.6rem;
    }

    @include tablet {
      font-size: 2.7rem;
    }
  }

  p {
    color: #575756;
    font-size: 2rem;
    line-height: 1.85em;

    @include desktop-small {
      font-size: 1.8rem;
    }

    @include tablet {
      font-size: 1.5rem;
    }
  }
}