.social {
  width: 290px;
  height: 50px;
  @include large-mobile {
    position: absolute;
    right: 5%;
    width: auto;
  }
  @include large-mobile-landscape {
    width: 190px;
  }
  ul {
    width: 196px;
    @include large-mobile {
      flex-direction: row;
      width: 150px;
    }
    @include small-mobile {
      width: 130px;
    }
  }
}

.social-icon {
  display: block;
  width: 32px;
  height: 32px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: .20;
  text-indent: -9999px;
  @include large-mobile {
    opacity: 1;
  }
  &:hover, .active {
    opacity: 1;
  }
}

.mail {
  background-image: url('../images/icon_mail.png');
  @extend .social-icon;
}
.facebook {
  background-image: url('../images/icon_facebook.png');
  @extend .social-icon;
}
.twitter {
  background-image: url('../images/icon_twitter.png');
  @extend .social-icon;
}
.linkedin {
  background-image: url('../images/icon_linkedIn.png');
  @extend .social-icon;
}
.pinterest {
  background-image: url('../images/icon_Pinterest.png');
  @extend .social-icon;
}