.search-results {
  li {
    margin-bottom: 5em;
  }

  .search-title {
    font-family: $sub_header_font_demi;
    font-size: 1.3rem;
    margin: 0;
    text-transform: uppercase;

    a {
      color: $sitepurple;
    }
  }

  .excerpt {
    color: #878787;
    font-family: $body_font;
    font-size: 2.6rem;
    margin: 0.25em 0;

    .keyword {
      color: $sitegold;
      font-style: normal;
    }
  }
}
