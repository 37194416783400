.agencies {
    .agency-intro {
        color: #737372;
        @include large-mobile {
            margin: 4rem auto;
        }
        .content {
            padding: 0 62px 0 30px;
            position: relative;
            width: 56.26%;
            z-index: 2;
            @include large-mobile {
                width: 95%;
                padding: 0;
                margin: 0 auto;
            }
            h2 {
                font-size: 4.8rem;
                line-height: 1.4;
                margin-bottom: 1em;
                @include tablet {
                    font-size: 2.4em;
                }
                @include large-mobile {
                    font-size: 4.2rem;
                    margin-bottom: 0.5em;
                    line-height: 1.2;
                }
            }
            p {
                font-size: 2rem;
                line-height: 1.8;
                padding-left: 75px;
                @include tablet {
                    font-size: 0.9em;
                    padding-left: 50px;
                }
                @include large-mobile {
                    padding-left: 0;
                }
            }
        }
        figure {
            margin: -25% 0 0;
            position: relative;
            width: 100%;
            z-index: 1;
            @include large-mobile {
                margin: 0 auto;
                width: 95%;
            }
            .mobile-grid {
                display: none;
                @include large-mobile {
                    display: block;
                }
            }
            .desktop-grid {
                display: block;
                @include large-mobile {
                    display: none;
                }
            }
            img {
                display: block;
                height: auto;
                width: 100%;
            }
        }
    }

    .benefits {
        background: url('../images/home/map.png') no-repeat center center;
        color: #696968;
        padding: 125px 0;
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include large-mobile {
          padding: 75px 0;
        }
        .flex-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h1 {
                margin-bottom: 0.75em;
                text-align: left;
                font-size: 8em;
                width: 100%;
                @include large-mobile {
                    font-size: 13vw;
                    margin-bottom: 0;
                }
            }
        }
        .slides {
            margin: 0 auto;
            width: 70%;
            @include large-mobile {
                width: 100%;
            }
        }
        .slide {
            height: 0;
            opacity: 0;
            overflow: hidden;
            pointer-events: none;
            transition-delay: 0.75s;
            transition: 0.75s opacity ease;
            h3 {
                font-size: 6rem;
                letter-spacing: 0.02em;
                text-transform: lowercase;
            }
            p {
                color: #575756;
                font-size: 20px;
                line-height: 1.8;
                text-align: center;
                @include large-mobile {
                    font-size: 15px;
                }
            }
            &.active {
                height: auto;
                opacity: 1;
                pointer-events: auto;
                transition-delay: 0;
            }
        }
    }

    .members {
        height: inherit;
        padding: 10rem 0 15rem;

        @include large-mobile {
            padding: 2em 0 6em;
        }

        grid {
            justify-content: flex-start;

            @include large-mobile {
                justify-content: center;
            }
        }
    }
}
section.testimonials {
    max-width: 960px;

    @include large-mobile {
        width: 100%;
    }
}

.testimonials blockquote {
    background-color: $sitegrey;
    border-left: 3px solid $siteltgreen;
    margin-bottom: 12em;
    padding: 72px 0 112px;
    position: relative;
    @include tablet {
        margin: 0 0 6em 5%;
        padding: 30px 0 80px;
    }
    q {
        color: #575756;
        display: block;
        font-family: $header_font_bold;
        font-size: 2rem;
        line-height: 2;
        margin: 40px 6% 0;
        @include large-mobile {
            font-size: 3.7vw;
            line-height: 1.75;
        }
        &:before {
            color: #e8e9ea;
            content: "“";
            display: block;
            font-size: 10rem;
            line-height: 0;
            margin-left: -20px;
        }
        &:after {
            display: none;
        }
    }
    cite {
        align-items: center;
        background-color: $sitepurple;
        color: #fff;
        display: flex;
        font-style: normal;
        justify-content: space-around;
        left: 6%;
        margin-left: 170px;
        padding: 28px 0;
        position: absolute;
        transform: translateY(-50%);
        top: 100%;
        width: 65%;

        @include tablet {
            display: block;
            height: 110px;
            left: 0;
            margin-left: 0;
            padding: 18px 0 0 130px;
            width: 100%;
        }

        figure {
            margin: -40px 0 0;
            position: absolute;
            right: 100%;
            top: 0;
            width: 170px;

            img {
                height: auto;
                width: 100%;
            }

            @include tablet {
                left: -3px;
                margin-top: 0;
                right: auto;
                width: 110px;
            }
        }

        p {
            font-size: 1.8rem;
            line-height: 1.3;
            margin: 0;
            text-transform: lowercase;

            strong {
                display: block;
                font-family: $sub_header_font_demi;
            }

            &:first-of-type {
                font-family: $header_font_bold;
                font-size: 2.6rem;
                font-weight: bold;
            }

            @include desktop-small {
                &:first-of-type {
                    font-size: 2rem;
                }

                &:last-child {
                    font-size: 1.4rem;
                    margin-top: 0.75em;
                }
            }

            @include small-mobile {
                &:first-of-type {
                    font-size: 1.4rem;
                }

                &:last-child {
                    font-size: 1.2rem;
                    margin-top: 0.25em;
                }
            }
        }
    }
}

section.agency-leadership {
    padding-bottom: 14rem;

    @include large-mobile {
        padding-bottom: 6rem;
    }
}