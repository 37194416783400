header {
  background-color: rgba(#fff, 0);
  position: relative;
  transition: 0.35s background-color ease;
  z-index: 100;

  .open-nav & {
    background-color: rgba(#fff, 1);
  }

  .flex-container {
    align-items: center;
    height: 120px;
    display: flex;
    justify-content: space-between;
    max-width: 1520px;
    @include small-mobile {
      height: 75px;
    }
  }

  .logo {
    position: relative;
    a {
      @include image_replace('global/logo.png', 200px, 90px);
      background-size: contain;
      margin-right: 15px;
      @include large-mobile {
        width: 150px;
        height: 70px;
      }
      @include small-mobile {
        width: 90px;
        height: 40px;
      }
    }
    p {
      bottom: 16px;
      color: #ae9b7a;
      font-size: 1.8rem;
      left: 100%;
      margin: 0;
      position: absolute;
      text-transform: lowercase;
      white-space: nowrap;
      @include tablet {
        display: none;
      }
    }
  }

  .actions {
    a {
      display: inline-block;
    }
  }

  a.login {
    color: #b4b5b4;
    font-size: 2rem;
    margin-right: 70px;
    text-transform: lowercase;
  }

  a.toggle {
    background-color: $sitegold;
    border: 0;
    border-radius: 0;
    color: #fff;
    //font-family: $header_font_bold;
    font-size: 1.75rem;
    padding: 12px 0;
    position: relative;
    text-align: left;
    text-indent: 20px;
    text-transform: lowercase;
    width: 175px;
    @include large-mobile {
      width: 175px;
      text-indent: 15px;
      font-size: 1.8rem;
    }
    @include medium-mobile {
      width: 135px;
      padding: 5px 0;
    }
    &:after {
      @include image_replace('global/nav-toggle.png', 25px, 21px);
      content: '';
      margin-top: -11px;
      position: absolute;
      right: 20px;
      top: 50%;
      @include large-mobile {
        right: 15px;
      }
      @include medium-mobile {
        transform: scale(.75);
        right: 10px;
      }
    }
    .open-nav & {
      &:after {
        background-position: left bottom;
      }
    }
  }

  .navigation {
    background-color: $siteblue;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding-top: 65px;
    pointer-events: none;
    position: absolute;
    transition: 0.35s all ease;
    top: 100%;
    width: 100%;
    @include desktop-small {
      padding-top: 75px;
    }
    @include large-mobile {
      padding-top: 0;
    }
    .search & {
      position: relative;
      top: 0;
    }
    .open-nav & {
      max-height: 2000px;
      opacity: 1;
      pointer-events: auto;
    }
    .main-navigation {
      height: auto;
      max-width: 1200px;
      padding-bottom: 50px;
      position: relative;
      @include desktop-small {
        padding-bottom: 50px;
      }
      @include large-mobile {
        padding: 0;
        width: 100%;
      }
      & > ul {
        justify-content: space-around;
        opacity: 1;
        transition: 0.5s all cubic-bezier(0.620, -0.005, 0.310, 1.000);
        width: 90%;
        @include large-mobile {
          flex-direction: column;
          margin: 40px 0 40px 5%;
        }
      }
      li {
        font-family: $header_font_bold;
        font-size: 3.2rem;
        letter-spacing: 1px;
        @include desktop-small {
          font-size: 2.4rem;
          padding-right: 10px;
        }
        @include tablet {
          margin: 0;
        }
        &:last-child{
          @include large-mobile {
            margin: 0;
          }
        }
        ul {
          transition: 0.5s all cubic-bezier(0.620, -0.005, 0.310, 1.000);
          overflow: hidden;
          @include large-mobile {
            //            max-height: 0;
          }
          &.active {
            max-height: 100vh;
          }
          li {
            font-family: $body_font;
            font-size: 2rem;
            padding: 6px 0;
            @include tablet {
              font-size: 1.5rem;
            }
          }
        }
      }
      a {
        color: #b4b5b4;
        &:hover {
          color: #fff;
        }
      }
      .open-search & {
        & > ul {
          opacity: 0;

          @include large-mobile {
            opacity: 1;
          }
        }
      }
    }
    form {
      background-color: $siteblue;
      height: 100%;
      position: absolute;
      right: 0;
      top: -15px;
      transition: 0.35s all ease;
      width: 6%;
      z-index: 2;
      @include large-mobile {
        background-color: #246f97;
        max-height: 0;
        overflow: hidden;
        position: relative;
        top: 0;
        width: 100%;
        margin-bottom: 0;

        &.active, .open-search & {
          max-height: 80px;
        }
      }
      fieldset {
        border-left: 1px solid #63a0c0;
        height: 70px;
        padding-left: 40px;
        position: relative;
        z-index: 1;
        @include large-mobile {
          border: 0;
          margin: 0 5%;
          padding: 0;
        }
        input {
          background-color: transparent;
          border: 0;
          color: #fff;
          font-family: $header_font_bold;
          font-size: 4.8rem;
          height: 100%;
          overflow: hidden;
          padding: 0;
          transition: 0.35s all ease;
          white-space: nowrap;
          width: 0;
          @include large-mobile {
            font-family: $sub_header_font_demi;
            width: 80%;
            font-size: 1.8rem;
          }
        }
        input:focus {
          border: 0;
        }
        ::-webkit-input-placeholder {
          color: #448db4;
        }
        :-moz-placeholder {
          color: #448db4;
        }
        ::-moz-placeholder {
          color: #448db4;
        }
        :-ms-input-placeholder {
          color: #448db4;
        }
      }
      button {
        background: url('../images/global/loupe.png') no-repeat right center;
        background-size: 20px 20px;
        border: 0;
        height: 70px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        right: 0;
        text-indent: 100%;
        top: 0;
        white-space: nowrap;
        width: 62px;
        z-index: 2;
        @include large-mobile {
          right: 5%;
        }
      }
      .open-search & {
        width: 100%;
        fieldset {
          input, label {
            width: 80%;
          }
        }
      }
    }
    .social-navigation {
      background-color: #404040;
      padding: 30px 0;
      text-align: center;
      @include large-mobile {
        height: 64px;
        padding: 0;
        position: relative;
      }

      .search-toggle {
        display: none;

        @include large-mobile {
          @include image_replace('global/loupe.png', 20px, 20px);
          @include vertical_align();
          background-size: 20px 20px;
          opacity: 0.75;
          overflow: visible;
          right: 20px;
          z-index: 2;

          &:after {
            background-color: #fff;
            content: '';
            display: block;
            height: 35px;
            left: -32px;
            position: absolute;
            top: -7px;
            width: 1px;
          }

          .open-search & {
            background-image: url('../images/global/close.png');
          }
        }
      }

      ul {
        @include max_width(492px);

        @include large-mobile {
          @include vertical_align();
          justify-content: flex-start;
          z-index: 1;
        }
      }

      li {
        margin-bottom: 0;

        @include large-mobile {
          margin: 0 20px;
        }
      }

      a {
        @include image_replace('global/social-icons.png', 31px, 31px);
        background-size: auto 31px;
        opacity: 0.75;
        transition: 0.35s opacity ease;
        &.twitter {
          background-position: center top;
        }
        &.linkedin {
          background-position: right top;
        }
        &:hover {
          opacity: 1;
        }

        @include large-mobile {
          background-size: auto 24px;
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}