/* Section Nav
 * Goes underneath mastheads and links to pages within a directory
----------------------------------------------------------------------------------------------------*/

.section-nav {
  background-color: $sitegrey;
  font-size: 1.8rem;
  padding: 0.5em 0 1em;
  position: relative;
  @include desktop-only {
    font-size: 2rem;
  }
  @include tablet {
    font-size: 1.5rem;
    padding-top: 1.5em;
  }
  @include large-mobile {
    display: none;
  }
  &:after {
    background-color: inherit;
    bottom: 99%;
    content: '';
    display: block;
    height: 26px;
    left: 38%;
    right: 0;
    position: absolute;
  }
  ul {
    margin: 0 auto;
    text-align: right;
    width: 90%;
  }
  li {
    display: inline-block;
    margin-right: 5%;
    @include tablet {
      margin-right: 2.5%;
    }
  }
  a {
    border-bottom: 3px solid transparent;
    color: #b4b5b4;
    padding-bottom: 0.15em;
  }
  li.current a, a:hover {
    border-bottom-color: $sitegold;
    color: #909190;
  }
}