.terms, .privacy {
  .info{
    max-width: 960px;
    padding: 12rem 0;
    margin: 0 auto;
    height: inherit;

    @include tablet {
      padding: 10rem 0;
    }

    @include large-mobile {
      padding: 6rem 0;
    }
  }
  h2 {
    font-family: $header_font_bold;
    color:  #575756;
    font-size: 2.6rem;
    line-height: 60px;
    @include large-mobile {
      font-size: 2.2rem;
      line-height: 1.5;
    }
  }
  .callout {
    font-size: 2.2rem;
    @include large-mobile {
      font-size: 1.8rem;
    }
  }
  p{
    font-size: 1.8rem;
    color:  #575756;
    @include large-mobile {
      font-size: 1.4rem;
    }
  }
  strong {
    font-family: $header_font_bold;
  }
}